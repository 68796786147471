/*
Documentation for hierarchyFields JavaScript function

Author: slinkybass
Version: 2.0

This script sets up event listeners on parent elements to control the visibility and behavior of child elements based on certain conditions. It is designed to handle form field hierarchies dynamically.

Events
- change: Triggered when the value of the parent element changes.
- input: Triggered when there is an input on the parent element.

Functionality
1. It checks the type of the parent element (checkbox, radio, or other input types) to determine the value.
2. Based on the parent's value and the specified conditions, it shows or hides the corresponding child elements.
3. It handles required attributes for child elements based on visibility.
4. It clears or resets certain types of child elements if needed.
5. It manages the visibility of parent card elements based on the visibility of their children.

Options (data-hf)
- data-hf-parent: Identifies the parent elements that control the visibility and behavior of child elements.
- data-hf-child: Associates child elements with a specific parent element.
- data-hf-default-value: This value can be included in the child element. Indicates the default value of the child element when it is hidden and shown again.
- data-hf-parent-value: This value can be included in the child element. Specifies the value that the parent field must have for the child field to be shown. If no value is specified, the child field will be shown when the parent field has a value other than null or empty.
- data-hf-keep-value: This value can be included in the child element. Indicates whether the child element should retain its value when it is hidden and shown again.
- data-hf-save-value: This value can be included in the child element. Indicates whether the child element should save its value when it is hidden and restore the original value when it is shown again.
- data-hf-saved-value: This property is used temporary in the child element if data-hf-save-value is true. It is used to store the value of the child element when it is hidden and restore the original value when it is shown again.
- data-hf-show: This property indicates the child element should be shown and setted readonly instead of hidden.

Special Cases
- The script also handles tomselect, and flatpickr elements.

*/

document.addEventListener("DOMContentLoaded", () => {
	initHierarchyFields();
});
document.addEventListener("ea.collection.item-added", () => {
	initHierarchyFields();
});

function initHierarchyFields() {
	document.querySelectorAll("[data-hf-parent]").forEach((parent) => {
		var childs = document.querySelectorAll('[data-hf-child="' + parent.getAttribute("data-hf-parent") + '"]');
		if (childs.length) {
			const handleHierarchyFields = () => {
				hierarchyFields(parent, childs);
			};
			parent.addEventListener("input", handleHierarchyFields);
			handleHierarchyFields();
		}
	});
}

function hierarchyFields(parent, childs) {
	const parentType = parent.getAttribute("type");
	const parentIsCheckboxOrRadio = parentType === "checkbox" || parentType === "radio";

	// Get parent value
	var parentValue = null;
	switch (parentType) {
		case "checkbox":
			parentValue = parent.checked;
			break;
		case "radio":
			parentValue = document.querySelector("[name='" + parent.name + "']:checked")?.value;
			break;
		default:
			parentValue = parent.value;
			break;
	}
	parentValue = parseFieldValue(parentValue);

	// Convert NodeList to Array
	childs = Array.from(childs);

	// Loop through divs childs and set attributes to them from the parent
	const divsInsideChilds = childs.filter((child) => child.tagName.toLowerCase() === "div");
	divsInsideChilds.forEach((div) => {
		const fieldsInsideDiv = Array.from(div.querySelectorAll("input, select, textarea"));

		fieldsInsideDiv.forEach((field) => {
			const defaultValue = div.hasAttribute("data-hf-default-value") ? parseFieldValue(div.getAttribute("data-hf-default-value")) : null;
			const parentValue = div.hasAttribute("data-hf-parent-value") ? parseFieldValue(div.getAttribute("data-hf-parent-value")) : null;
			const keepValue = div.hasAttribute("data-hf-keep-value") ? parseFieldValue(div.getAttribute("data-hf-keep-value")) : null;
			const saveValue = div.hasAttribute("data-hf-save-value") ? parseFieldValue(div.getAttribute("data-hf-save-value")) : null;
			const show = div.hasAttribute("data-hf-show") ? parseFieldValue(div.getAttribute("data-hf-show")) : null;
			if (defaultValue) {
				field.setAttribute("data-hf-default-value", defaultValue);
			}
			if (parentValue) {
				field.setAttribute("data-hf-parent-value", parentValue);
			}
			if (keepValue) {
				field.setAttribute("data-hf-keep-value", keepValue);
			}
			if (saveValue) {
				field.setAttribute("data-hf-save-value", saveValue);
			}
			if (show) {
				field.setAttribute("data-hf-show", show);
			}
		});

		div.removeAttribute("data-hf-default-value");
		div.removeAttribute("data-hf-parent-value");
		div.removeAttribute("data-hf-keep-value");
		div.removeAttribute("data-hf-save-value");
		div.removeAttribute("data-hf-show");

		childs = childs.concat(fieldsInsideDiv);
		childs = childs.filter((child) => child !== div);
	});

	childs.forEach(function (child) {
		const childContainer = child.closest(".form-group").parentNode;

		const childType = child.getAttribute("type");
		const childIsCheckboxOrRadio = childType === "checkbox" || childType === "radio";
		const childIsTomSelect = child.classList.contains("tomselected") && child.hasOwnProperty("tomselect");
		const childIsFlatpickr = child.classList.contains("flatpickr-input") && child.hasOwnProperty("_flatpickr");
		const childDefaultValue = child.hasAttribute("data-hf-default-value") ? parseFieldValue(child.getAttribute("data-hf-default-value")) : null;
		const parentValueToShow = child.hasAttribute("data-hf-parent-value") ? parseFieldValue(child.getAttribute("data-hf-parent-value")) : parentIsCheckboxOrRadio ? true : null;
		const childMustKeepValue = child.hasAttribute("data-hf-keep-value") ? parseFieldValue(child.getAttribute("data-hf-keep-value")) : false;
		const childMustSaveValue = child.hasAttribute("data-hf-save-value") ? parseFieldValue(child.getAttribute("data-hf-save-value")) : false;
		const childSavedValue = child.hasAttribute("data-hf-saved-value") ? parseFieldValue(child.getAttribute("data-hf-saved-value")) : null;
		const childShow = child.hasAttribute("data-hf-show") ? parseFieldValue(child.getAttribute("data-hf-show")) : null;

		if ((parentValueToShow !== null && parentValueToShow === parentValue) || (parentValueToShow === null && parentValue !== null)) {
			// Show the child
			if (childShow) {
				child.classList.remove('disabled');
				child.removeAttribute('readonly');
			} else {
				childContainer.classList.remove("d-none");
			}

			if (!child.disabled && !childMustKeepValue) {
				if (childIsCheckboxOrRadio) {
					if (childMustSaveValue && childSavedValue !== null) {
						child.checked = childSavedValue;
						child.removeAttribute("data-hf-saved-value");
						child.dispatchEvent(new Event("change"));
					} else if (childDefaultValue) {
						child.checked = childDefaultValue;
						child.dispatchEvent(new Event("change"));
					}
				} else if (childIsTomSelect) {
					if (childMustSaveValue && childSavedValue !== null) {
						child.tomselect.setValue(child.multiple ? childSavedValue.split(",") : childSavedValue);
						child.removeAttribute("data-hf-saved-value");
						child.dispatchEvent(new Event("change"));
					} else if (childDefaultValue) {
						if (!child.value) {
							child.tomselect.setValue(child.multiple ? childDefaultValue.split(",") : childDefaultValue);
						}
						child.dispatchEvent(new Event("change"));
					}
				} else if (childIsFlatpickr) {
					if (childMustSaveValue && childSavedValue !== null) {
						child._flatpickr.setDate(childSavedValue);
						child.removeAttribute("data-hf-saved-value");
						child.dispatchEvent(new Event("change"));
					} else if (childDefaultValue) {
						if (!child.value) {
							child._flatpickr.setDate(childDefaultValue);
						}
						child.dispatchEvent(new Event("change"));
					}
				} else if (!childIsCheckboxOrRadio && !childIsTomSelect && !childIsFlatpickr) {
					if (childMustSaveValue && childSavedValue !== null) {
						child.value = childSavedValue;
						child.removeAttribute("data-hf-saved-value");
						child.dispatchEvent(new Event("change"));
					} else if (childDefaultValue) {
						if (!child.value) {
							child.value = childDefaultValue;
						}
						child.dispatchEvent(new Event("change"));
					}
				}
			}

			// Set required value
			if (child.hasAttribute("data-hf-required")) {
				childContainer.querySelectorAll("label.hf-required").forEach((label) => {
					label.classList.remove("hf-required");
					label.classList.add("required");
				});
				child.setAttribute("required", child.getAttribute("data-hf-required"));
				child.removeAttribute("data-hf-required");
				if (childIsFlatpickr) {
					child._flatpickr._input.setAttribute("required", child._flatpickr._input.getAttribute("data-hf-required"));
					child._flatpickr._input.removeAttribute("data-hf-required");
				}
			}
		} else {
			// Hide the child
			if (childShow) {
				child.classList.add('disabled');
				child.setAttribute('readonly', true);
			} else {
				childContainer.classList.add("d-none");
			}

			if (!child.disabled && !childMustKeepValue) {
				if (childIsCheckboxOrRadio) {
					if (childMustSaveValue && child.checked) {
						child.setAttribute("data-hf-saved-value", child.checked);
					}
					child.checked = false;
					child.dispatchEvent(new Event("change"));
				} else if (childIsTomSelect) {
					if (childMustSaveValue && child.tomselect.getValue()) {
						child.setAttribute("data-hf-saved-value", child.multiple ? child.tomselect.getValue().join(",") : child.value);
					}
					child.tomselect.clear();
					child.dispatchEvent(new Event("change"));
				} else if (childIsFlatpickr) {
					if (childMustSaveValue && child.value) {
						child.setAttribute("data-hf-saved-value", child.value);
					}
					child._flatpickr.clear();
					child.dispatchEvent(new Event("change"));
				} else if (!childIsCheckboxOrRadio && !childIsTomSelect && !childIsFlatpickr) {
					if (childMustSaveValue && child.value) {
						child.setAttribute("data-hf-saved-value", child.value);
					}
					child.value = null;
					child.dispatchEvent(new Event("change"));
				}
			}

			// Save required value
			if (child.hasAttribute("required")) {
				childContainer.querySelectorAll("label.required").forEach((label) => {
					label.classList.remove("required");
					label.classList.add("hf-required");
				});
				child.setAttribute("data-hf-required", child.getAttribute("required"));
				child.removeAttribute("required");
				if (childIsFlatpickr) {
					child._flatpickr._input.setAttribute("data-hf-required", child._flatpickr._input.getAttribute("required"));
					child._flatpickr._input.removeAttribute("required");
				}
			}
		}

		// Manage card visibility
		const card = child.closest(".card");
		if (card) {
			const childrenContainers = card.querySelectorAll(".form-group");
			let showedChildrens = 0;
			childrenContainers.forEach((child) => {
				if (!child.parentNode.classList.contains("d-none")) {
					showedChildrens++;
				}
			});
			if (showedChildrens > 0) {
				card.classList.remove("d-none");
			} else {
				card.classList.add("d-none");
			}
		}
	});
}

// Helpers
function parseFieldValue(val) {
	try {
		return JSON.parse(val);
	} catch (error) {
		return val === "" ? null : val;
	}
}
