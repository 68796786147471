// jQuery
const $ = require("jquery");
global.$ = global.jQuery = $;

// Moment
import moment from "moment";
global.moment = moment;
import "moment/locale/es.js";

// HierarchyFields
import "./custom-js/hierarchyFields.js";
import "./custom-js/hierarchyFieldsCustom.js";

// Tabler
import bootstrapModal from 'bootstrap/js/dist/modal.js'
global.bootstrapModal = bootstrapModal;
import "@tabler/core";

// Fontawesome
import fontawesome from "@fortawesome/fontawesome-free";
import "@fortawesome/fontawesome-free/css/all.css";
fontawesome.config = { autoReplaceSvg: false };

// BasicLightbox
import * as basicLightbox from "basiclightbox";
import "basiclightbox/dist/basicLightbox.min.css";

// SmartCrop
import SmartCrop from "smartCrop";
global.SmartCrop = SmartCrop;

// Swal
import Swal from 'sweetalert2';
global.Swal = Swal;

// ON LOAD
document.addEventListener("DOMContentLoaded", function () {
	App.setMomentLocale();
	App.autoHideAlert();
	App.lightbox();
	App.passwordFields();
});

const App = (() => {
	const setMomentLocale = () => {
		moment.locale($("html").attr("lang"));
	};
	const autoHideAlert = () => {
		var alert = $(".alert.position-fixed");
		setTimeout(function () {
			alert.slideUp(400, function () {
				$(this).remove();
			});
		}, 3000);
	};
	const lightbox = () => {
		document.querySelectorAll('[data-action="zoom"]').forEach((link) => {
			link.addEventListener("click", (e) => {
				e.preventDefault();
				var img =
					'<img width="1400" height="900" src="' +
					link.getAttribute("href") +
					'">';
				basicLightbox.create(img).show();
			});
		});
	};
	const passwordFields = () => {
		document.querySelectorAll(".btn-pass").forEach((btn) => {
			btn.addEventListener("click", (e) => {
				var btnIcon = btn.querySelector("i");
				var passField = btn.parentNode.querySelector("input");
				if (passField.type == "password") {
					passField.type = "text";
					btnIcon.classList.replace("ti-eye-off", "ti-eye");
				} else {
					passField.type = "password";
					btnIcon.classList.replace("ti-eye", "ti-eye-off");
				}
			});
		});
	};
	return {
		setMomentLocale: setMomentLocale,
		autoHideAlert: autoHideAlert,
		lightbox: lightbox,
		passwordFields: passwordFields,
	};
})();

// CSS
import "./styles/app.scss";
