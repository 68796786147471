global.hierarchyFieldsCustom = hierarchyFieldsCustom;
function hierarchyFieldsCustom(parent, childs) {
	var value = null;
	if (parent.attr("type") == "checkbox") {
		value = parent.is(":checked");
	} else if (parent.attr("type") == "radio") {
		value = $('[name=' + parent.attr('name') +']:checked').val();
	} else {
		value = parent.val();
	}
	childs.each(function () {
		var child = $(this);
		var showValue = child.attr("data-hfcustom-value") ?? true;
		var childContainer = child.closest(".form-element");
		if (value == showValue) {
			childContainer.removeClass("d-none");
			if (child.attr("data-required")) {
				child.attr("required", child.attr("data-required"));
				child.removeAttr("data-required");
			}
		} else {
			childContainer.addClass("d-none");
			if (child.attr("type") == "checkbox" || child.attr("type") == "radio") {
				if (child.is(':checked')) {
					child.prop("checked", false);
					child.trigger('change');
				}
			} else if ($(child).hasClass("flatpickr-input") && child[0].hasOwnProperty('_flatpickr') && child.val()) {
				$(child).val(null);
				child[0]._flatpickr.clear();
				child.trigger('change');
			} else if (child.val()) {
				child.val(null);
				child.trigger('change');
			}
			if (child.attr("required")) {
				child.attr("data-required", child.attr("required"));
				child.removeAttr("required");
			}
		}
	});
}
